import { useEffect, useState } from 'react';
import type { AppProps } from 'next/app';
import { DefaultSeo } from 'next-seo';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
// import { ReactQueryDevtools } from 'react-query/devtools';
import { TNavigation } from '@/lib/sanity/queries/navigationQuery';

import { NavigationProvider } from '@/contexts/navigation';

import '@fagmobler/config/styles/global.css';
import '@/styles/googlemaps.css';

import Head from 'next/head';
import { useRouter } from 'next/router';
import { trackExperiment, trackPage } from '@/lib/analytics';
import DefaultLayout from '@/components/layout';
import { Breadcrumb } from '@fagmobler/types';
import {
  ALGOLIA_APPLICATION_ID,
  ALGOLIA_SEARCH_API_KEY,
} from '@/lib/algolia/searchClient';

// const debug = process.env.NODE_ENV === 'development';

const growthBook = new GrowthBook({
  apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST,
  clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: process.env.NEXT_PUBLIC_GROWTHBOOK_DEV_MODE === 'true',
  trackingCallback: (experiment, result) => {
    console.log('Experiment viewed', experiment.key, result);
    trackExperiment(experiment.key, result);
  },
});
growthBook.init({
  streaming: true,
});

export function FagmoblerApp({
  Component,
  pageProps,
}: AppProps<{
  navigation?: TNavigation;
  breadcrumbs?: Breadcrumb[];
  dehydratedState: any;
}>) {
  const router = useRouter();
  const routerEvents = router?.events;
  const [queryClient] = useState(
    () =>
      new QueryClient({
        // defaultOptions: {
        //   queries: {
        //     cacheTime: 1000 * 60 * 5, // 5 minutes
        //     staleTime: 1000 * 5, // 5 seconds,
        //   },
        // },
      })
  );

  useEffect(() => {
    if (routerEvents) {
      routerEvents.on('routeChangeComplete', trackPage);

      return () => {
        routerEvents.off('routeChangeComplete', trackPage);
      };
    }
  }, [routerEvents]);

  useEffect(() => {
    if (window.aa) {
      window.aa('init', {
        appId: ALGOLIA_APPLICATION_ID,
        apiKey: ALGOLIA_SEARCH_API_KEY,
      });
      console.log('loaded', window.aa);
    } else {
      console.error('no window.aa');
    }
  }, []);

  useEffect(() => {
    // Get existing ID from localStorage or generate a new one
    const getOrCreateUserId = () => {
      const storageKey = 'growthbook-user-id';
      const existingId = localStorage.getItem(storageKey);

      if (existingId) {
        return existingId;
      }

      // Generate a random ID if none exists
      const newId = Math.random().toString(36).substring(2, 15);
      localStorage.setItem(storageKey, newId);
      return newId;
    };

    growthBook.setAttributes({
      id: getOrCreateUserId(),
    });
  }, []);

  const breadcrumbs = pageProps.breadcrumbs;

  return (
    <GrowthBookProvider growthbook={growthBook}>
      <QueryClientProvider client={queryClient}>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        <NavigationProvider {...pageProps.navigation} breadcrumbs={breadcrumbs}>
          <Hydrate state={pageProps.dehydratedState}>
            <DefaultSeo
              defaultTitle="Fagmøbler"
              titleTemplate="%s - Fagmøbler"
              description="Møbler - Høy kvalitet, gode priser"
              openGraph={{
                type: 'website',
                locale: 'en_NO',
                url: 'https://www.fagmobler.no',
                site_name: 'Fagmøbler',
              }}
            />
            <Head>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=5"
              />
            </Head>
            <DefaultLayout>
              <Component {...pageProps} />
            </DefaultLayout>
          </Hydrate>
        </NavigationProvider>
      </QueryClientProvider>
    </GrowthBookProvider>
  );
}

export default FagmoblerApp;
