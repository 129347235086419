import { GTM_ID, SHOULD_TRACK } from './constants';
import { DataLayer, EcommerceObject, getDatalayer } from './utils';
import { OmniumStore } from '@fagmobler/omnium';

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export function trackEvent(
  event: DataLayer['event'],
  ecommerceObject: EcommerceObject,
  store?: OmniumStore
) {
  const ecommerce: EcommerceObject = {
    ...ecommerceObject,
    store_id: store?.id || undefined,
    store_name: store?.name || undefined,
  };

  const datalayer = getDatalayer(event, ecommerce);

  if (!SHOULD_TRACK) {
    return;
  }

  // GTM
  if (GTM_ID && window?.dataLayer) {
    // Send to Google Tag Manager
    window.dataLayer.push({
      ecommerce: null,
    });

    window.dataLayer.push(datalayer);
  }
}

export const trackPage = (e: any) => {
  const dataLayer = {
    event: 'view_page',
    path: window.location.href,
  } as DataLayer;
  if (!SHOULD_TRACK) {
    return;
  }
  window.dataLayer?.push(dataLayer);
};

export const trackExperiment = (key: string, result: any) => {
  const dataLayer = {
    event: 'experiment_viewed',
    path: window.location.href,
    experiment_key: key,
    experiment: result,
  } as DataLayer;
  if (!SHOULD_TRACK) {
    return;
  }
  window.dataLayer?.push(dataLayer);
};
